import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import chunk from 'lodash/chunk'
import sum from 'lodash/sum'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import some from 'lodash/some'
import mapValues from 'lodash/mapValues'
import swal from 'sweetalert'

import Api from 'app/modules/Api'
import Picture from 'app/modules/Picture'

import UploadContext from './context'


const CHUNK_SIZE = 6

function formatBytes(bytes, decimals) {
   if (bytes == 0) return '0 Bytes'
   let k = 1024,
       dm = decimals || 2,
       sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
       i = Math.floor(Math.log(bytes) / Math.log(k))
   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

class UploadProvider extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      checkUnprocessedCount: 0,
      files: [],
      isUploading: false,
      isProcessing: false,
      originalTitle: '',
      progress: { total: 0, done: 0 },
      onDrop: this.onDrop.bind(this)
    }
    this.startUpload = this.startUpload.bind(this)
    this.checkSpace = this.checkSpace.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
    this.processPictures = this.processPictures.bind(this)
    this.cancelUpload = this.cancelUpload.bind(this)
  }

  componentDidMount () {
    this.setState({ originalTitle: document.title.toString() })

    window.onbeforeunload = () => {
      return this.state.isUploading ?
      'Envio de fotos em progresso! Ao sair da página o envio será cancelado.' :
      undefined
    }
  }

  updateProgress (progress) {
    this.setState({
      ...progress,
      isUploading: (progress.total !== progress.sent) || (progress.total > 0 && !progress.allChunksSent)
    })
  }

  async cancelUpload () {
    this.setState({ isUploading: false })
    Api.abortUploads()
    window.location.reload()
  }

  async startUpload (files, uploadPositions) {
    const { fileCategory, uploadPath, uploadVariables } = this.props.params
    const totalBytes = sum(map(files, ({ size }) => size))
    const sentBytes = {}

    const mainProgress = {
      total: files.length,
      totalBytes: formatBytes(totalBytes, 2),
      progress: 0,
      sent: 0,
      sentBytes: 0,
      allChunksSent: false
    }

    this.updateProgress(mainProgress)
    this.setState({ files })

    const chunks = chunk(files, CHUNK_SIZE)

    for (const chunkIndex in chunks) {
      const chunk = chunks[chunkIndex]
      await Promise.all(chunk.map(async (file, picIndex) => {
        await Api.upload(uploadPath, uploadVariables, [file], (bytes) => {
          sentBytes[chunkIndex + '-' + picIndex] = bytes
          mainProgress.sentBytes = sum(map(sentBytes, (sent) => sent))
          mainProgress.progress = mainProgress.sentBytes / totalBytes
          document.title = `(${parseInt(mainProgress.progress * 100)}%) ${this.state.originalTitle}`
          mainProgress.sentBytes = formatBytes(mainProgress.sentBytes, 2)
          return this.updateProgress(mainProgress)
        }, fileCategory)
        mainProgress.sent = mainProgress.sent + 1
        return true
      }))

      mainProgress.done = mainProgress.sent
      this.updateProgress(mainProgress)
    }

    document.title = this.state.originalTitle.toString()
    this.updateProgress({ allChunksSent: true })

    swal('Envio de fotos concluído com sucesso!', `${mainProgress.sent} ${mainProgress.sent === 1 ? 'foto enviada' : 'fotos enviadas'}`, { icon: 'success', })
    this.props.onFinish(uploadPositions)
  }

  async checkSpace (files) {
    const requested = files.reduce((a, b) => a + b.size, 0)
    const stats = await Api.get('/customer/stats')

    const keys = this.props.params.statsKeys

    return {
      requested,
      file_maxsize: (+stats.subscription.limits[keys.file_maxsize]) * 1048576,
      limit: (+stats.subscription.limits[keys.space]) * 1048576,
      used: (+stats.usage[keys.space]) * 1048576,
      get available() {
        return (this.limit - this.used) > 0 ? this.limit - this.used : 0
      },
      get hasEnough() {
        if (isNaN(this.limit)) return true
        return this.available >= this.requested
      }
    }
  }

  async onDrop (files) {
    const space = await this.checkSpace(files)
    if (!space.hasEnough) return notEnoughSpace(space)

    const toUpload = await (
      checkDuplicates(this.props.currentFiles, files)
      .then((f) => checkTooBig(f, space.file_maxsize))
    )

    const uploadPositions = files.map((f, position) => ({ name: f.name, position }))
    if (process.env.REV_ENABLE_PREPROCESSING) {
      this.processPictures(toUpload)
      .then((processedFiles) => {
        this.startUpload(processedFiles, uploadPositions)
      })
    } else {
      this.startUpload(toUpload, uploadPositions)
    }
  }

  async processPictures (files) {
    const processedFiles = []
    this.setState({
      isProcessing: true,
      processingProgress: {
        done: 0,
        total: files.length
      }
    })
    for (const file of files) {
      const processed = (await Picture.processFile(file, 3840)) || file
      processedFiles.push(processed)
      this.setState({
        processingProgress: {
          ...this.state.processingProgress,
          done: this.state.processingProgress.done + 1
        }
      })
    }
    this.setState({ isProcessing: false })
    return processedFiles
  }

  render () {
    return (
      <UploadContext.Provider value={this.state}>
        {this.props.children}
      </UploadContext.Provider>
    )
  }
}

async function checkTooBig (files, file_maxsize) {
  if (file_maxsize && some(files, (f) => f.size > file_maxsize)) {
    await swal({
      text: `Foram detectadas uma ou mais fotos com mais de ${formatBytes(file_maxsize)}, estas fotos serão ignoradas.`,
      title: 'Arquivos maiores que o limite',
        icon: 'warning',
        buttons: {
        confirm: {
          text: 'Continuar envio',
          value: true,
          visible: true,
          className: 'warning',
          closeModal: true
        }
      }
    })
    return files.filter((f) => f.size <= file_maxsize)
  }
  return files
}

async function checkDuplicates (currentFiles, newFiles) {
  const picMap = mapValues(keyBy(currentFiles, 'title'), 'file_size')
  let toUpload = [...newFiles]
  if (some(newFiles, (f) => picMap[f.name] && picMap[f.name] === f.size)) {
    const ignore = await willIgoreDuplicates()
    if (ignore) {
      toUpload = toUpload.filter((f) => !picMap[f.name] || picMap[f.name] !== f.size)
    }
  }
  return toUpload
}

async function willIgoreDuplicates () {
  return swal({
    text: 'Foram detectados um ou mais arquivos que já estão no álbum',
    title: 'O que deseja fazer?',
      icon: 'warning',
      buttons: {
        cancel: {
        text: 'Enviar todos',
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: 'Ignorar duplicados',
        value: true,
        visible: true,
        className: 'warning',
        closeModal: true
      }
    }
  })
}

async function notEnoughSpace (space) {
    const content = document.createElement('div')
    ReactDOM.render((
      <div style={{ textAlign: 'left', fontSize: '13px' }}>
        O seu limite de espaço do plano contratado não é suficiente para fazer envio das fotos selecionadas.
        <br />
        <br /><strong>Imagens selecionadas:</strong> {formatBytes(space.requested, 2)}
        <br /><strong>Espaço contratado:</strong>  {formatBytes(space.limit, 2)}
        <br /><strong>Espaço utilizado:</strong>  {formatBytes(space.used, 2)}
        <br /><strong>Espaço disponível:</strong> {formatBytes(space.available, 2)}
      </div>
    ), content)

    const openPlans = await swal({
      dangerMode: true,
      content,
      title: 'Sem espaço suficiente!',
        icon: 'error',
        buttons: {
          cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Contratar mais espaço',
          value: true,
          className: 'good',
          visible: true,
          closeModal: true
        }
      }
    })
    if (openPlans) {
      window.location.href = 'https://www.picsize.com.br/upgrade'
    }
}

export default UploadProvider
