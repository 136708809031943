import Api from './Api'
import swal from 'sweetalert'
import ReactGA from 'react-ga'

import { App } from '../index'

const based64Encode = window.btoa
const based64Decode = window.atob

const getCurrentTS = () => Math.floor(+new Date() / 1000)

const Auth = {
  updatingSession: false,

  // eslint-disable-next-line no-unused-vars
  async tryLogin(email, password, cat) {
    const user = await this.getUserData()

    return user ? { success: true, user } : { success: false }

    // const { success, user } = await Api.post('/login/authenticate', { email, password, cat })

    // if (success) {
    //   if (user.subscription && !user.subscription.active) {
    //     await this.logout(false)
    //     return window.location = '/error/blocked'
    //   }

    //   this.userData = user
    // }

    // return { success, user }
  },

  async getUserData() {
    const res = await Api.get('/auth/user')
    if (!res || !res.success || !res.data) {
      return false
    }
    this.userData = res.data
    return res
  },

  async trySignup(name, email, password, plan, ll) {
    const result = await Api.post('/account/signup', { name, email, password, plan, ll })
    if (result.success) {
      this.userData = result.user
    }
    return result
  },
  async crossLoginUrl(service) {
    const result = await Api.post('/login/cross', { service })
    if (!result.onPlan) {
      await notPlanned()
      return
    }
    return result.url
  },
  async crossLogin(service) {
    const url = await this.crossLoginUrl(service)
    if (url) window.location = url
  },
  async firstAccess() {
    const res = await Api.get('/account/first-access')
    if (!res || !res.success) {
      console.warn(res)
    }
    return res
  },
  async isUnderLimit(resourceKey, limitKey, willAdd) {
    const { subscription, usage } = await Api.get('/customer/stats')
    if (!subscription || !usage) return false

    if (!subscription.limits[limitKey]) return true

    const is = +usage[resourceKey] + willAdd <= +subscription.limits[limitKey]
    if (!is) {
      overLimit()
      const params = { service: resourceKey, limitKey, limitValue: +subscription.limits[limitKey] }
      Api.post('/account/logReachedLimit', params)
    }
    return is
  },
  async logout(redir = true) {
    localStorage.removeItem('auth-time')
    localStorage.removeItem('auth-userData')
    localStorage.removeItem('auth-lastRemoteCheck')
    try {
      await Api.get('/auth/logout')
    } catch (ex) {
      console.warn(ex)
    }
    if (!redir) return true
    return (window.location = `${process.env.ACCOUNT_CP}/logout`)
  },
  async checkSession() {
    localStorage.setItem('auth-lastRemoteCheck', getCurrentTS())
    Api.get('/customer/details')
      .then(async ({ subscription }) => {
        if (!subscription || subscription.active) {
          this.userData = {
            ...this.userData,
            subscription
          }
          return
        }
        await this.logout(false)

        return (window.location = '/error/blocked')
      })
      .catch(() => this.logout())
  },
  async updateSession() {
    if (this.updatingSession) {
      return
    }
    this.updatingSession = true
    localStorage.setItem('auth-lastRemoteCheck', getCurrentTS())
    Api.get('/account/session', true)
      .then(async session => {
        if (session && session.user) {
          const { subscription } = session.user
          if (subscription && subscription.active) {
            this.userData = session.user
            return App.forceUpdate()
          }
        }
        await this.logout()
      })
      .then(() => {
        setTimeout(() => (this.updatingSession = false), 1e3)
      })
      .catch(err => {
        console.warn(err)
        setTimeout(() => (this.updatingSession = false), 1e3)
      })
  },
  get lastSessionCheck() {
    return +localStorage.getItem('auth-lastRemoteCheck') || 0
  },
  get isAuthenticated() {
    // remote check every 5 minutes
    if (this.lastSessionCheck && getCurrentTS() - this.lastSessionCheck > 60 * 5) {
      this.checkSession()
    }

    const is = localStorage.getItem('auth-time') !== null && !!this.userData

    if (is) {
      this.trackUser()
    }

    return is
  },
  trackUser() {
    if (window.isTrackingLoaded) return

    const { name, email, customer_id, subscription } = this.userData

    ReactGA.set({ userId: customer_id })

    if (!this.isPersonificated) {
      window.dataLayer.push({
        ps_user: {
          name,
          email,
          customer_id,
          ...(!!subscription && {
            activation_date: subscription.activation_date,
            active: subscription.active,
            expired: subscription.expired,
            plan_ids: subscription.plan_ids,
            trial: subscription.trial
          })
        }
      })
    }

    if (window.Conpass) {
      window.Conpass.init({
        name,
        email,
        custom_fields: {
          customer_id,
          ...(!!subscription && {
            activation_date: subscription.activation_date,
            active: subscription.active,
            expired: subscription.expired,
            plan_ids: subscription.plan_ids,
            trial: subscription.trial
          })
        }
      })
    }

    window.isTrackingLoaded = true
  },
  hasPermission(service) {
    if (this.userData && this.userData.subscription &&
      this.userData.subscription.limits && this.userData.subscription.limits[service]) {
      return true
    }
    return false
  },
  get isTrial() {
    const { subscription } = this.userData

    if (!subscription || !subscription.trial || !subscription.expiration_date) {
      return false
    }

    return true
  },
  get isTrialExpired() {
    const { subscription } = this.userData
    return subscription && this.isTrial && subscription.expired
  },
  get isFreemium() {
    const { subscription } = this.userData
    return (
      (subscription && Array.isArray(subscription.plan_codes) && !!subscription.plan_codes.filter(c => c === 'col_freemium').length) ||
      false
    )
  },
  get isCustomWatermarkAuthorized(){
    const { limits } = this.userData.subscription
    return limits.custom_watermark
  },
  get isExportSelectedListAuthorized(){
    const { limits } = this.userData.subscription
    return limits.export_selected_list
  },
  get isBuyPhotosAuthorized(){
    const { limits } = this.userData.subscription
    return limits.sel_buy_photos
  },
  get isPersonificated() {
    // é "true" quando o login é feito através do painel de admin
    return this.userData && this.userData.personificated
  },
  get needBlackfridayPopup(){
    const plans = ["col_free", "col_freemium", "col_basic", "sel_start"]
    return this.userData.subscription.plan_codes.filter(plan_code => plans.includes(plan_code)).length
  },
  get userData() {
    const encodedData = localStorage.getItem('auth-userData')

    if (!encodedData || based64Decode(encodedData) === 'undefined') {
      return false
    }

    return JSON.parse(decodeURIComponent(based64Decode(encodedData)))
  },
  set userData(val) {
    localStorage.setItem('auth-time', getCurrentTS())
    localStorage.setItem('auth-userData', based64Encode(encodeURIComponent(JSON.stringify(val))))
    localStorage.setItem('auth-lastRemoteCheck', getCurrentTS())
  },
  get userSessionVersion() {
    const user = this.userData
    return (user && user.version) || null
  },
  async getUserSelectionInfo(customer_id) {
    return await Api.get(`/customer/collection/${customer_id}`)
  }
}

Api.addUpdateSessionHandle(version => {
  if (Auth.isAuthenticated && version !== Auth.userSessionVersion) {
    Auth.updateSession()
  }
})

export default Auth

async function notPlanned() {
  const openPlans = await swal({
    dangerMode: true,
    text: 'O seu plano atual não inclui esta solução!',
    title: 'Fora do plano',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true
      },
      confirm: {
        text: 'Fazer upgrade',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'https://www.picsize.com.br/upgrade'
  }
}

async function overLimit() {
  const openPlans = await swal({
    dangerMode: true,
    text:
      'No plano que você tem contratado, quando o pagamento é feito mensalmente, tem liberado na solução de Aprovação de Álbuns 1 álbum para teste. Para ter acesso a 10 álbuns basta alterar a forma de pagamento para anual, é importante reforçar que você continuará pagando o mesmo valor no período de um ano, mas ao invés de pagar em 12 meses, mês a mês, você pagará R$ 598,80 divididos em 12 vezes de R$ 49,90.',
    title: 'Fora do plano',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true
      },
      confirm: {
        text: 'Fazer upgrade',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'https://www.picsize.com.br/upgrade'
  }
}
