import React, {Component} from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import Loading from 'app/widgets/Loading'
import toastr from 'toastr'

import Api from 'app/modules/Api'
import Auth from 'app/modules/Auth'
import Modal from 'app/widgets/Modal2'

import AlbumSteps from '../common/Steps'
import ReviewersBox from './ReviewersBox'
import ReviewerWidget from './ReviewerWidget'

import ReviewerModal from './ReviewerModal'

const DEFAULT_REVIEWER = {
  name: '',
  email: '',
  message: 'O seu álbum já está disponível! Segue o link de acesso para que você possa aprovar.',
  limit_date: ''
}

class AlbumReview extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isLoading: true,
      isSortable: false,
      reviewers: [],
    }

    this.goToStep = this.goToStep.bind(this)
    this.getData = this.getData.bind(this)
    this.openReviewerModal = this.openReviewerModal.bind(this)
    this.addReviewer = this.addReviewer.bind(this)
    this.deleteReviewer = this.deleteReviewer.bind(this)
  }

  getData () {
    return new Promise(async (resolve) => {
      const album_id = this.props.params.id
      const {reviewers, album} = await Api.get(`/revise/reviewer?album_id=${album_id}`)
      if (album && album.id) {
        return this.setState({album, reviewers, isLoading: false}, resolve)
      }
      this.props.router.push('/album/list')
      resolve()
    })
  }

  componentDidMount () {
    this
    .getData()
    .then(() => {
      const {query} = this.props.location
      console.log(query)
      if (query && query.modal === 'new') {
        if (!this.state.reviewers.length) {
           return this.openReviewerModal(false, true)
        }
        this.openReviewerModal({
          ...formatReviewerData(this.state.reviewers[0]),
          state: 0
        }, true)
      }
    })
  }

  openReviewerModal (reviewer, send) {
    const onSubmit = async (data) => {
      if (!reviewer) {
        return await this.addReviewer(data)
      }
      return await this.updateReviewer(data, send)
    }

    Modal.show(<ReviewerModal inputs={reviewer || DEFAULT_REVIEWER} onSubmit={onSubmit} send={send} />)
  }

  async addReviewer (data) {
    const {displayName: customer_name, email: customer_email} = Auth.userData
    const album_id = this.props.params.id
    const result = await Api.post(`/revise/reviewer?album_id=${album_id}`, {
      ...data,
      customer_name,
      customer_email
    })
    if (result) {
      toastr.success('Cliente adicionado!')
      this.getData()
    } else {
      toastr.error('Erro ao adicionar cliente!')
    }
    Modal.hide()
  }

  async updateReviewer (data, send) {
    const {displayName: customer_name, email: customer_email} = Auth.userData
    const album_id = this.props.params.id
    const result = await Api.post(`/revise/reviewer/${data.id}`, {
      ...data,
      send,
      customer_name,
      customer_email,
      album_id
    })
    if (result) {
      toastr.success('Informações atualizadas!')
      this.getData()
    } else {
      toastr.error('Erro ao atualizar informações!')
    }
    Modal.hide()
  }

  async deleteReviewer (reviewer) {
    const willDelete = await swal({
      title: `Excluir cliente ${reviewer.name}?`,
      text: 'Esta ação não pode ser revertida.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Excluir'
      }
    })
    if (willDelete) {
      const album_id = this.props.params.id
      await Api.del(`/revise/reviewer/${reviewer.id}`, {
        album_id
      })
      toastr.success('Cliente removido!')
      this.getData()
    }
  }

  goToStep (stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/album/${this.props.params.id}/${stepName}`)
  }

  render() {
    return (
      <div className="row">
        <AlbumSteps currentStep={3} reachedStep={3} onClickStep={this.goToStep} />
        <ReviewersBox openReviewerModal={this.openReviewerModal}>
          {
            (this.state.isLoading && <Loading />) ||
            (!this.state.reviewers.length ?
            <CreateFirst openReviewerModal={this.openReviewerModal} /> :
            this.state.reviewers.map((reviewer, index) => (
              <ReviewerWidget
                key={`rw-${index}`}
                openReviewerModal={this.openReviewerModal}
                deleteReviewer={this.deleteReviewer}
                reviewer={formatReviewerData(reviewer)}
              />
            )))
          }
        </ReviewersBox>
      </div>
    )
  }
}

function formatReviewerData (reviewer) {
  return {
    id: reviewer.id,
    name: reviewer.Client && reviewer.Client.name,
    email: reviewer.Client && reviewer.Client.email,
    limit_date: reviewer.limit_date || '',
    message: reviewer.extradata.message || '',
    created_at: reviewer.created_at,
    state: reviewer.state,
    hash: reviewer.hash
  }
}


function CreateFirst ({openReviewerModal}) {
  return (
    <div className="portlet-body" style={{minHeight: '300px', padding: '50px 0'}}>
      <div className="text-center">
        <p style={{fontSize: '64px'}}>
          <i className="fa fa-user" />
        </p>
        <h3>
          Esse álbum ainda não foi enviado para nenhum cliente!
        </h3>
        <h4>
          <a onClick={() => openReviewerModal(false, true)}>Clique aqui</a> para enviar
        </h4>
      </div>
    </div>
  )
}

AlbumReview.propTypes = {
  router: PropTypes.object,
  params: PropTypes.object
}


export default AlbumReview
