import Main from './layouts/Main'
import BasicLayout from '~/layouts/Basic'
import Auth from './modules/Auth'
import CustomerService from './modules/CustomerService'

import LoginView from './pages/Login'

import AlbumList from './pages/Album/List'
import NewAlbum from './pages/Album/New'
import AlbumInfo from './pages/Album/Info'
import AlbumSheets from './pages/Album/Sheets'
import AlbumReview from './pages/Album/Review'

import Home from './pages/Home'
import { WizardPage } from './pages/Wizard'
import Support from './pages/Support'

const routes = {
  childRoutes: [
    {
      path: '/wizard',
      component: BasicLayout,
      onEnter(nextState, replace, calback) {
        CustomerService.stats.subscribe(() => calback())
      },
      childRoutes: [
        {
          path: '*',
          component: WizardPage
        }
      ]
    },
    {
      path: '/',
      component: Main,
      indexRoute: {
        onEnter: (nextState, replace) => {
          replace('/album/list')
        }
      },
      onEnter: (nextState, replace, calback) => {
        if (!Auth.isAuthenticated) {
          return replace('/login')
        }
        calback()
        // CustomerService.stats.subscribe(s => {
        // if (s.collectionInfo.count < 1 && s.galleryInfo.count < 1 && Auth.isFreemium) {
        // replace('/wizard/step-one')
        // }
        // calback()
        // })
      },
      childRoutes: [
        {
          path: 'album/',
          indexRoute: { onEnter: (nextState, replace) => replace('/album/list') },
          childRoutes: [
            { path: 'list', component: AlbumList },
            { path: 'new', component: NewAlbum },
            { path: ':id/info', component: AlbumInfo },
            { path: ':id/sheets', component: AlbumSheets },
            { path: ':id/review', component: AlbumReview }
          ]
        },
        {
          path: '/logout',
          onEnter: (nextState, replace) => {
            Auth.logout()
            replace('/')
          }
        }
      ]
    },
    {
      path: '/login',
      // onEnter: (nextState, replace) => {
      //   if (Auth.isAuthenticated) {
      //     return replace('/collection/list')
      //   }
      // },
      component: LoginView
    }
  ]
}

export default routes
