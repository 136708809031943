import React, { Component } from 'react'

import Auth from '~/modules/Auth'
import { AlbumIcon, GalleryIcon, SelectionIcon, SiteIcon } from '~/widgets/Icons'
import FeatureItem from './FeatureItem'

import './FeaturesList.scss'

export default class FeaturesList extends Component {
  features = [
    {
      title: 'Entrega de Fotos',
      icon: GalleryIcon,
      text:
        'Envie fotos em alta para o seu cliente fazer o download ou acessar por uma galeria on-line.',
      actions: [
        {
          text: 'Criar',
          url: '/gallery'
        },
        {
          text: 'Minhas Entregas',
          url: '/gallery/list'
        }
      ]
    },
    {
      title: 'Seleção de Fotos',
      icon: SelectionIcon,
      text:
        'Envie fotos com segurança para o seu cliente escolher.',
      actions: [
        {
          text: 'Criar',
          url: '/collection/new'
        },
        {
          text: 'Minhas Seleções',
          url: '/collection/list'
        }
      ]
    },
  ]

  constructor() {
    super(...arguments)
    this.addSite()
    this.addAlbum()
  }

  addAlbum(){
    if (!Auth.hasPermission('rev')) {
      return
    }
    this.features.push({
      title: 'Prova de Álbum',
      icon: AlbumIcon,
      text:
        'Crie um álbum folheável e envie para o seu cliente aprovar.',
      actions: [
        {
          text: 'Criar',
          url: '/album/new'
        },
        {
          text: 'Meus Álbuns',
          url: '/album/list'
        }
      ]
    })
  }

  addSite() {
    if (!Auth.hasPermission('site')) {
      return
    }
    this.features.push({
      title: 'Gerenciar meu Site',
      icon: SiteIcon,
      text:
        'Gerencie o seu site frequentemente, insira novos eventos e compartilhe nas redes \
        sociais, para divulgar o seu trabalho!',
      actions: [
        {
          text: 'Gerenciar Site',
          cb: async () => {
            const url = await Auth.crossLoginUrl('site')
            if (url) {
              window.open(url)
            }
          }
        }
      ]
    })
  }

  render() {
    return (
      <div className="scp-wizard-features-list">
        {this.features.map((i, id) => (
          <FeatureItem key={id} data={i} />
        ))}
      </div>
    )
  }
}
