import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import { Dropdown, Menu } from 'antd'

import Auth from '~/modules/Auth'


function TopMenu() {
  // Temp workground
  async function gotoAccount(s) {
    const url = await Auth.crossLoginUrl('account')
    if (typeof url === 'string') window.location = url.replace('/info#', `/${s}#`)
  }

  const menu = (
    <Menu style={{ zIndex: 1000 }}>
      {/* <Menu.Item key="0">
        <a onClick={() => Auth.crossLogin('account')}>
          <i className="icon-user" /> Minha Conta
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a onClick={() => gotoAccount('profile')}>
          <i className="icon-user" /> Perfil
        </a>
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={() => gotoAccount('financial')}>
          <i className="icon-wallet" /> Financeiro
        </a>
      </Menu.Item> */}
      <Menu.Item key="3">
        <Link to="/logout">
          <i className="icon-key" /> Sair
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="top-menu">
      <ul className="nav navbar-nav pull-right">
        <li className="dropdown dropdown-user">
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="dropdown-toggle">
              <span className="username username-hide-on-mobile"> {Auth.userData.displayName || Auth.userData.name} </span>
              <i className="fa fa-angle-down" />
            </a>
          </Dropdown>
        </li>
      </ul>
    </div>
  )
}
TopMenu.propTypes = {
  children: PropTypes.element
}

export default TopMenu
