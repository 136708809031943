import React, {Component} from 'react'
import ReactGA from 'react-ga'

import Api from '~/modules/Api'
import CustomerService from '~/modules/CustomerService'
import Auth from '~/modules/Auth'
import ReachedLimitDialog from '../../widgets/ReachedLimitDialog/ReachedLimitDialog'

import AlbumSteps from './common/Steps'
import AlbumInfoForm from './common/InfoForm'


class NewAlbum extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isSubmiting: false,
      form: {title: '', description: ''}
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount(){
    const hasPermission = Auth.hasPermission('rev')
    if(!hasPermission){
      ReachedLimitDialog.open({
        title: 'Recurso indisponível',
        message: 'Infelizmente seu plano não oferece suporte para este recurso.'
      })
      return this.props.router.push('/home')
    }

    const { permission } = await Api.get('/album/permission')
      if(!permission){
        ReachedLimitDialog.open({
          title: 'Limite atingido',
          message: `O limite de ${Auth.userData.subscription.limits.rev_max_albums} albums do seu plano foi atingido.`
        })
        CustomerService.pushReachedPlanLimitLog('album')
        return this.props.router.push('/album/list')
      }
  }

  handleChange(e) {
    const formState = this.state.form
    formState[e.target.name] = e.target.value
    this.setState({form: formState})
  }

  async processForm(event) {
    event.preventDefault()

    this.setState({isSubmiting: true})

    const canCreateNew = await Auth.isUnderLimit('rev_albums', 'rev_max_albums', 1)

    if (!canCreateNew) return false

    const {push} = this.props.router
    const {title, description} = this.state.form
    const result = await Api.post('/revise/album', {title, description})

    this.setState({isSubmiting: false})

    if (result && result.album) {
      ReactGA.event({
        category: 'Album',
        action: 'Criou um novo álbum'
      })

      return push(`/album/${result.album.id}/sheets`)
    }

    return push('/album/list')
  }

  render() {
    return (
      <div className="row">
        <AlbumSteps currentStep={1} reachedStep={1} />
        <div className="col-md-12">
          <div className="portlet light" style={{maxWidth: '1380px', margin: 'auto'}}>
            <div className="portlet-title">
              <div className="caption">
                <i className="fa fa-book font-dark" />
                <span className="caption-subject font-dark sbold uppercase">
                  Dados do álbum
                </span>
              </div>
            </div>
            {
              <div className="alert alert-warning">
                Esta ferramenta é voltada para aprovação de álbuns. Caso queira utilizar a ferramenta de seleção de fotos <a href="/collection/list">clique aqui</a>
              </div>
            }
            <div className="portlet-body form row">
              <div className="col-md-12 col-md-offset-0 col-lg-8 col-lg-offset-2">
                <AlbumInfoForm isNew={true} handleChange={this.handleChange} onSubmit={this.processForm} />
              </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default NewAlbum
