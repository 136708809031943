import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import './Blackfriday.scss'

import BgExiPopup from 'public/img/black-friday-picsize-2019.jpg'

export function trap(props) {
  setTimeout(() => {
    open(props)
  }, 500)
}

export function open(props) {
  const host = document.getElementById('modal-area')

  if (!host) {
    console.warn('HostElementNotFoundError')
    return false
  }

  ReactDom.render(<ExitPopup close={close} {...props} />, host)

  function close(type = false) {
    if (type === 'exit-button') ReactGA.event({ category: 'Exit-Popup', action: 'Clicou Fechar Popup'})
    ReactDom.unmountComponentAtNode(host)
  }

  return {
    close
  }
}

const handleClickAdvertising = (close) => {
  window.open('https://picsize.com.br/optimizepress/blackfriday/', 'blank')
  close()
}

const ExitPopup = ({ close }) => {
  return (
    <div className='scp-exit-popup-overlay'>
      <div className='scp-exit-popup' style={{ backgroundImage: `url(${BgExiPopup})` }}
      >
        <button className='close-btn' onClick={() => close('exit-button')}>
          <i className='fa fa-close'></i>
        </button>

        <div 
          className='popup-content'
          onClick={() => handleClickAdvertising(close)}
          style={{ cursor: 'pointer' }}
        >
        </div>

      </div>
    </div>
  )
}

ExitPopup.propTypes = {
  close: PropTypes.func,
}
