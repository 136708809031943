import Auth from './Auth'
import $ from 'jquery/src/core'
import 'jquery/src/ajax'
import 'jquery/src/ajax/xhr'

const apiUrl = process.env.API_URL

function Api() {
  const xhrPool = []
  let updateSessionHandle

  return {
    get,
    post,
    del,
    upload,
    abortUploads,
    put,
    passRecovery,
    singUpClient,
    addUpdateSessionHandle
  }

  function abortUploads() {
    $.each(xhrPool, (idx, jqXHR) => {
      jqXHR.abort()
    })
  }

  async function singUpClient(data) {
    try {
      return fetch(`${apiUrl}/customer/clients/singUp`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(e => {
          return e.json().then(e => {
            return e
          })
        })
        .catch(e => console.warn(e))
    } catch (error) {
      return { error }
    }
  }

  async function passRecovery(path, email, hash) {
    //console.log(hash)
    //console.log(`${apiUrl}${path}`)
    try {
      return await fetch(`${apiUrl}${path}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          hash: hash
        })
      })
    } catch (e) {
      return console.warn(e)
    }
  }

  function get(path, ignoreMiddlewares = false) {
    // window.Pace.restart()
    return new Promise(resolve => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'GET',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: ignoreMiddlewares ? resolve : sessionMiddleware.bind(this, resolve),
        error: error => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }

  function post(path, data, json = false) {
    // window.Pace.restart()
    return new Promise(resolve => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'POST',
        data,
        ...(json && {
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }),
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        },
        success: sessionMiddleware.bind(this, resolve),
        error: error => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }

  function upload(path, params, files, onProgress, fileKey = 'images') {
    const formData = new FormData()
    for (const file of files) {
      formData.append(fileKey, file, file.name)
    }
    for (const param in params) {
      formData.append(param, params[param])
    }
    return new Promise(resolve => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'POST',
        data: formData,
        crossDomain: true,
        beforeSend: jqXHR => {
          xhrPool.push(jqXHR)
        },
        xhr: () => {
          const myXhr = $.ajaxSettings.xhr()
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              'progress',
              e => {
                if (!e.lengthComputable || !onProgress) return false
                const max = e.total
                const current = e.loaded
                return onProgress(current, current / max)
              },
              false
            )
          }
          return myXhr
        },
        xhrFields: {
          withCredentials: true
        },
        contentType: false,
        processData: false,
        success: resolve,
        error: error => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }

  function del(path, data) {
    return new Promise(resolve => {
      $.ajax(`${apiUrl}${path}`, {
        method: 'DELETE',
        crossDomain: true,
        data,
        xhrFields: {
          withCredentials: true
        },
        success: resolve,
        error: error => {
          if (error.status === 403) return Auth.logout()
          resolve({ error })
        }
      })
    })
  }

  function addUpdateSessionHandle(fn) {
    updateSessionHandle = typeof fn === 'function' ? fn : null
  }

  function sessionMiddleware(next, result, status, xhr) {
    const userSessionVersion = +xhr.getResponseHeader('User-Session-Version') || null
    if (userSessionVersion && typeof updateSessionHandle === 'function') {
      updateSessionHandle(userSessionVersion)
    }
    next(result, status, xhr)
  }
}

async function put(newData, path) {
  try {
    await fetch(`${apiUrl}${path}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newData)
    })
  } catch (e) {
    console.warn(e)
  }
}

// eslint-disable-next-line new-cap
export default Api()
